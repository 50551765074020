<!-- Begin Header -->
<header>
    <!-- Gradient background -->
    <div class="header-background"></div>
    <!-- End gradient background -->
    <!-- Navbar -->
    <nav class="navbar navbar-unnanu-hire-app">
        <div class="home-container container">
            <div class="navbar-header topNavigationBar app-langing-topnavbar">
                <a class="navbar-brand" [routerLink]="['/dashboard']"><img src="../../../assets/images/logo-unnanu-hire.png" width="130"></a>
            </div>
            <div id="navbar" class="navbar-collapse">
                <ul class="account-menu nav navbar-nav navbar-right">
                    <li class="dashboard_li" [ngClass]="{'active_dli': getCurrentUrl() === '/dashboard'}">
                        <a [routerLink]="['/dashboard']">Dashboard</a>
                    </li>
                    <li class="dashboard_li"
                        [ngClass]="{'active_dli': getCurrentUrl() === '/jobboard' || getCurrentUrl() === '/jobboard/archive'}">
                        <a [routerLink]="['/jobboard']">Job Board</a>
                    </li>
                    <li class="dashboard_li" [upgradeSubscription]="userType==='4' || userType==='5'"
                        [ngClass]="{'active_dli': getCurrentUrl() === '/findTalent' || getCurrentUrl() === '/findTalent/lists' || getCurrentUrl().indexOf('/findTalent/search') !== -1 }">
                        <a [routerLink]="['/findTalent']">Find Talent </a>
                    </li>
                    <li class="dashboard_li" [upgradeSubscription]="userType==='5'"
                        [ngClass]="{'active_dli': getCurrentUrl() === '/messages' || getCurrentUrl().indexOf('/messages') !== -1 }">
                        <a [routerLink]="['/messages']">
                            Messages
                            <i class="dash-notification"
                                *ngIf="headerDataLoad && unreadMessagesCount > 0  || newMessageReceived">{{unreadMessagesCount }}</i>
                        </a>
                    </li>
                    <li class="dashboard_li" [upgradeSubscription]="userType==='4' || userType==='5'"
                        [ngClass]="{'active_dli': getCurrentUrl() === '/interviews' || getCurrentUrl() === '/interviews/past'}">
                        <a [routerLink]="['/interviews']">Interviews
                            <i class="dash-notification" *ngIf="headerDataLoad && unreadInterviewsCount > 0">{{ unreadInterviewsCount }}</i>
                        </a>
                    </li>
                    <li class="dashboard_li" [upgradeSubscription]="userType==='5'"
                        [ngClass]="{'active_dli': getCurrentUrl() === '/onboard' || getCurrentUrl() === '/onboard/packages' || getCurrentUrl() === '/onboard/templates' }">
                        <a [routerLink]="['/onboard']">Onboard
                            <i class="dash-notification" *ngIf="headerDataLoad && unreadOffersCount > 0">{{unreadOffersCount }}</i>
                        </a>
                    </li>
                    <li class="account_li dropdown" ngbDropdown>
						<a class="dropdown-toggle" ngbDropdownToggle id="dropdownMenu2" *ngIf="headerDataLoad">
							<span *ngIf="headerDataLoad">{{ userName | ellipsis:27 }}</span>
							&nbsp;
							<i class="img-account-dropdown img-sprite-1" alt=""></i>
						</a>
						<a class="dropdown-toggle" *ngIf="!headerDataLoad">
							<img src="../../../assets/images/pro-loading.gif" width="45">
						</a>
						<ul ngbDropdownMenu id="loggedin-user" class="dropdown-menu">
							<li>
								<a ngbDropdownItem (click)="CreateTalent()">Create Talent</a>
							</li>
							<li>
								<a  ngbDropdownItem (click)="InviteEmail()">Invite Talent</a>
							</li>
							<li class="">
								<a ngbDropdownItem [routerLink]="['settings']" target="_blank">Settings</a>
							</li>
							<li class="">
								<a ngbDropdownItem (click)="logout()">Sign Out</a>
							</li>
						</ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <!-- End navbar -->
</header>
<!-- End Header -->
