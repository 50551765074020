<div class="modal-header">
	<h4 class="modal-title" id="modal-title">
		<div class="header pull-left">
			{{(tagcount > 0) ? userTitle+' - Unnanu Score - '+tagsresumescore+' (Tags: '+tagcount+'/'+ttagscount+')' : userTitle}}
		</div>
	</h4>
	<button type="button" class="close" aria-label="Close" (click)="closeThisDialog()">
		<span aria-hidden="true">Close</span>
	</button>
</div>
<div class="modal-body">
	<div class="content" *ngIf="!showAllApplicantsPreloader">
		<div class="content-item" [hidden]="keywords.length">
			<p class="title" style="padding-top: 130px; padding-left: 180px; font-size: 20px;">No keywords are
				available.
			</p>
		</div>
		<div class="content-item" style="height: 33px; display: flex; justify-content: space-between;
    align-items: center;" *ngFor="let keyword of keywords | orderBy: ['-scoreper']">
			<div class="content-item-left pull-left">
				<span class="type" style="font-size: 14px; align-items: center;">
					<span>Weighted Score: </span>
					<span>{{(keyword.scoreper < 10) ? '__' + keyword.scoreper : ((keyword.scoreper < 100) ? '_'
							+keyword.scoreper : keyword.scoreper)}}%</span>
					</span>
					<span class="title" style="padding: 20px;">
						{{keyword.tag}}
					</span>
			</div>
			<div class="content-item-right pull-right" style="display: flex; align-items: center;">
				<span>Count: </span>
				<span>{{keyword.scorecount}}</span>
			</div>
		</div>
	</div>
	<div class="content" *ngIf="showAllApplicantsPreloader">
		<div class="content-item">
			<div class="animated-background header"></div>
			<div class="animated-background subtitle"></div>
		</div>
		<div class="content-item">
			<div class="animated-background header"></div>
			<div class="animated-background subtitle"></div>
		</div>
		<div class="content-item">
			<div class="animated-background header"></div>
			<div class="animated-background subtitle"></div>
		</div>
		<div class="content-item">
			<div class="animated-background header"></div>
			<div class="animated-background subtitle"></div>
		</div>
		<div class="content-item">
			<div class="animated-background header"></div>
			<div class="animated-background subtitle"></div>
		</div>
		<div class="content-item">
			<div class="animated-background header"></div>
			<div class="animated-background subtitle"></div>
		</div>
	</div>
</div>
