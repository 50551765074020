import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../core/services/user.service';
import { UtilityService } from '../../../core/services/utility.service';
import { environment } from 'src/environments/environment';
import { Md5 } from 'ts-md5';
import { JobBoardService } from '../../../core/services/jobBoard.service';
import { Router } from '@angular/router';
import { SocketIOService } from '../../../core/services/socket-io.service';
import { ProfileVideoApplicantComponent } from '../profile-video-applicant/profile-video-applicant.component';
import { ConfirmAlertComponent } from '../confirm-alert/confirm-alert.component';
import { ShowKeywordsComponent } from '../show-keywords/show-keywords.component';
import { ShowApplicantAnswersComponent } from '../show-applicant-answers/show-applicant-answers.component';
import { ShowJobApplicantNotesComponent } from '../show-notes/show-job-applicant-notes.component';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';

@Component({
	selector: 'show-applicant',
	templateUrl: './show-applicant.component.html',
	styleUrls: ['./show-applicant.component.scss']
})
export class ShowApplicantComponent implements OnInit {

	@Input() jobdata: any = {};
	@Input() jobid: any = null;
	@Input() jobtitle: any = null;
	@Input() jobrefid: any = null;
	@Input() jobBoardWidgetData: any[] = [];
	@Input() vacancyID: any = null;

	public jobBoardUrl: string = environment.urls.jobboardUrl;

	public userType: string = '';

	public url: string = '';
	public addToList: any[] = [];
	public list: any = {};
	public SavedList: any[] = [];
	public newList: any[] = [];
	public listName: any[] = [];
	public preLoader: any = [];

	public allApplicantsData: any[] = [];
	public recommendedApplicantsData: any[] = [];
	public rejectedApplicantsData: any[] = [];

	public showAllApplicantsPreloader: boolean = true;
	public showRejectedPreloader: boolean = true;
	public showLoadMoreApplicantsPreloader: boolean = true;
	public noDropDowns: boolean = true;

	//Lazy Loading Data
	private _loadApplicantsPage: number = 0;
	private _haveMoreRecommendedApplicants: boolean = true;
	private _haveMoreOtherApplicants: boolean = true;
	private _haveMoreRejectedApplicants: boolean = true;

	constructor(public utilityService: UtilityService,
		private _router: Router,
		private _modal: NgbActiveModal,
		private _modalService: NgbModal,
		private _userService: UserService,
		private _jobBoardService: JobBoardService,
		private _socketIOService: SocketIOService,
		private _toastr: ToastrService,
		private _cookieService: CookieService) {

	}

	// Property of the directive main popup

	ngOnInit(): void {

		this.newList = new Array(100).fill(true);

		this.url = this.jobBoardUrl + 'job/' + this.jobid + '/jid';
		this.userType = this._cookieService.get('account_type');
		this._callLoadApplicantsDataService(this.jobid);

		//catch new job application from Socket Service
		this._socketIOService.on('ApplicationRecieved', (jobID: any) => {
			//console.log('ApplicantsViewDirective : ApplicationRecieved Socket for Job ID : ' + jobID.toString());
			//console.log('loadApplicantsPage : ' + this._loadApplicantsPage);
			if (this._loadApplicantsPage > 0) {
				//console.log('if(loadApplicantsPage>0)');
				// Default socket subscription data load of the directive main popup
				this._getLoadedApplicantsListFromService(jobID);
			} else {
				//console.log('else of (loadApplicantsPage>0)');
				this._jobBoardService.getAllApplicantsData(jobID, 0).subscribe((response: any) => {
					if (response.status == 200 && response.Code == 200) {
						if (response.Data.job_applicants.length > 0) {
							response.Data.job_applicants.forEach((applicant: any, key: any) => {
								if (applicant.is_rejected) {
									this.rejectedApplicantsData.push(applicant);
								} else if (applicant.recruit_score < 75) {
									this.allApplicantsData.push(applicant);
								} else {
									this.recommendedApplicantsData.push(applicant);
								}
							});
						}
						if (response.Data.job_applicants.length > 0) {
							this._haveMoreOtherApplicants = true;
							this._haveMoreRecommendedApplicants = true;
							this._haveMoreRejectedApplicants = true;
						}
					}
				});

			}
		});

	}

	private _getLoadedApplicantsListFromService(jobID: any): void {
		// Default socket subscription property of the directive main popup
		let tempAllApplicants: any[] = [];
		let tempRecommendedApplicants: any[] = [];
		let tempRejectedApplicants: any[] = [];
		let startGetLoadedApplicantsPage: number = 0;
		//console.log('currentApplicantPage : ' + startGetLoadedApplicantsPage + '/' + this._loadApplicantsPage);
		this._jobBoardService.getAllApplicantsData(jobID, startGetLoadedApplicantsPage).subscribe((response: any) => {
			if (response.status == 200 && response.Code == 200) {
				if (response.Data.job_applicants.length > 0) {
					response.Data.job_applicants.forEach((applicant: any, key: any) => {
						if (applicant.is_rejected) {
							tempRejectedApplicants.push(applicant);
						} else if (applicant.recruit_score < 75) {
							tempAllApplicants.push(applicant);
						} else {
							tempRecommendedApplicants.push(applicant);
						}
					})
				}
				if (startGetLoadedApplicantsPage == this._loadApplicantsPage) {
					this.allApplicantsData = tempAllApplicants;
					this.recommendedApplicantsData = tempRecommendedApplicants;
					this.rejectedApplicantsData = tempRejectedApplicants;
					// console.log('allApplicantsData');
					// console.log(this.allApplicantsData);
					// console.log('recommendedApplicantsData');
					// console.log(this.recommendedApplicantsData);
					// console.log('rejectedApplicantsData');
					// console.log(this.rejectedApplicantsData);
					if (response.Data.job_applicants.length >= 10) {
						this._haveMoreOtherApplicants = true;
						this._haveMoreRecommendedApplicants = true;
						this._haveMoreRejectedApplicants = true;
					}
				}
				if (startGetLoadedApplicantsPage < this._loadApplicantsPage) {
					startGetLoadedApplicantsPage += 1;
					this._getLoadedApplicantsListFromService(jobID);
				}
			}
		});
	}

	// Load Applicants for a Job Post with Lazy Loading
	private _callLoadApplicantsDataService(vacancyID: any): void {
		this._jobBoardService.getAllApplicantsData(vacancyID, this._loadApplicantsPage).subscribe((response: any) => {
			// console.log('getAllApplicantsData response');
			// console.log(response);
			// hide preloader
			this.showAllApplicantsPreloader = false;
			this.showLoadMoreApplicantsPreloader = false;
			this.showRejectedPreloader = false;
			if (response.status === 200 && response.Code === 200) {
				if (!this.jobrefid) {
					this.jobdata.job_ref_id = response.Data.j_id;
				}
				if (response.Data.job_applicants.length > 0) {
					response.Data.job_applicants.forEach((applicant: any, key: any) => {
						if (applicant.is_rejected) {
							this.rejectedApplicantsData.push(applicant);
						} else if (applicant.recruit_score < 75) {
							this.allApplicantsData.push(applicant);
						} else {
							this.recommendedApplicantsData.push(applicant);
						}
					})
					this._haveMoreOtherApplicants = true;
				} else {
					this._haveMoreOtherApplicants = false;
					this._haveMoreRecommendedApplicants = false;
					this._haveMoreRejectedApplicants = false;
				}
			} else {
				this._haveMoreOtherApplicants = false;
				this._haveMoreRecommendedApplicants = false;
				this._haveMoreRejectedApplicants = false;
			}
		});
	}

	//go to user profile url
	goToUserProfile(profileID: string): void {
		window.open('/recruit/profile/' + profileID, '_blank');
	}

	//Lazy Loading methods
	loadMoreApplicants(): void {
		if (this._haveMoreRecommendedApplicants || this._haveMoreOtherApplicants || this._haveMoreRejectedApplicants) {
			if (!this.showLoadMoreApplicantsPreloader) {
				this._loadApplicantsPage += 1;
				this._callLoadApplicantsDataService(this.vacancyID);
				// console.log('loadMoreApplicants');
				this.showLoadMoreApplicantsPreloader = true;
			}
		} else {
			// console.log('No more Applicants');
		}
	}

	JobPopupPage(url: string): void {
		window.open(url, 'popup', 'width=1100,height=900,left=300,top=150');
	}

	//False other popups
	falseOtherPopups(): void {
		if (this.addToList) {
			this.addToList.forEach((value: any, key: any) => {
				this.addToList[key] = false;
			});
		}
	}

	falseOtherPopupsExceptThis(index: any): void {
		this.noDropDowns = false;
		if (this.addToList) {
			this.addToList.forEach((value: any, key: any) => {
				if (index !== key) {
					this.addToList[key] = false;
				}
			})
		}
	}

	getScore(recruit_score: number): string {
		return (recruit_score > 99) ? '99' : ((recruit_score < 10) ? '0' + ((recruit_score < 1) ? '0' : recruit_score.toString()) : recruit_score.toString());
	}

	//Create new list
	saveList(listData: any, userID: any, index: any): void {
		listData.Users = [];
		this.list = {};
		listData.Users.User_id = userID;
		let candidate: any = {};
		candidate.id = userID;
		// call createList service
		this._userService.createList(listData).subscribe((response: any) => {
			// check response
			if (response.Code === 200) {
				this._toastr.success(`Job listing created`);
				let tempList: any = {};
				tempList.Already_in = true;
				tempList.List_id = response.Data.List_id;
				tempList.List_title = listData.List_name;
				this.SavedList[index].unshift(tempList);
				// console.log(this.SavedList)
				this.checkboxClicked(response.Data.List_id, userID, true, listData.List_name, false)
			} else {
				if (response.Code === 401) {
					this._toastr.error(`List name already exist.`);
				} else {
					this._toastr.error(`Something is not right, please try again`);
				}
			}
			this.list.List_name = '';
		});
	}

	//Start new conversation
	startConversation(userData: any): void {
		// console.log('result message');
		// console.log(userData);
		let openConversation: any = {};
		openConversation.id = userData.recruit_id;
		openConversation.image_url = userData.profile_image_url;
		openConversation.location = userData.location;
		openConversation.name = userData.recruit_first_name + ' ' + userData.recruit_last_name;
		openConversation.position = userData.job_position;
		let date = new Date();
		// clear local storage before add data
		localStorage.clear();
		// set values in local storage
		localStorage.setItem(<string>new Md5().appendStr(date.toISOString().slice(0, -5)).end(), JSON.stringify(openConversation))
		// change location path to messages
		window.open(('/messages/' + new Md5().appendStr(date.toISOString().slice(0, -5)).end()), '_blank');
	}

	findTalentLists(index: any, recruitId: any, e: any): void {
		this.SavedList = [];
		this.preLoader = [];
		// preloader show
		this.preLoader[index] = true;
		// call service
		this._userService.getSelectedListsData(recruitId).subscribe((response: any) => {
			// preloader hide
			this.preLoader[index] = false;
			if (response.Code === 200) {
				this.SavedList[index] = response.Data.SavedList;
				// console.log('this.SavedList['+index+']');
				// console.log(this.SavedList[index]);
			}
		});
	}

	//CheckboxClicked
	checkboxClicked(listId: any, userId: any, status: any, listName: any, addToList: any): void {
		// create array
		let listData: any = {};
		listData.New_users = [];
		listData.Removed_users = [];
		listData.Search_list_id = listId;
		let Removed_users: any = {};
		let New_users: any = {};
		if (status) {
			New_users.User_id = userId;
			listData.New_users.push(New_users);
		} else {
			Removed_users.User_id = userId;
			listData.Removed_users.push(Removed_users);
		}
		// call updateList service
		this._userService.updateList(listData).subscribe((response: any) => {
			// temoprary disblaed checkbox until response coming
			// this.list.selected = false
			// check response
			if (response.Code === 200) {
				// release checkbox
				// this.list.selected = true
				if (listData.New_users.length > 0) {
					if (addToList) {
						this._toastr.success(`Added to '${this.utilityService.getLimitedWord(listName, 18)}'`);
					}
				} else {
					this._toastr.success(`Removed from  '${this.utilityService.getLimitedWord(listName, 18)}'`);
				}
			}
		});
	}

	applicantMarkRead(application: any, index: number, type?: number): void {

		if (this._router.url == '/dashboard') {
			// console.log(`this._router.url=='/dashboard'`);
			if (type == 1) {
				this.jobBoardWidgetData.forEach((job: any, key: any) => {
					if (job.vacancy_id == this.vacancyID && !application.is_read && job.total_new_applicants > 0) {
						job.total_new_applicants -= 1;
					}
				});
			} else {
				this.jobBoardWidgetData.forEach((job: any, key: any) => {
					if (job.vacancy_id == this.vacancyID && !application.is_read && job.total_rec_new_applicants > 0) {
						job.total_rec_new_applicants -= 1;
					}
				});
			}
		}

		this._jobBoardService.applicantMarkRead(this.vacancyID, application.recruit_id).subscribe((response: any) => {
			if (type == 1) {
				if (this.allApplicantsData[index]) {
					this.allApplicantsData[index].is_read = true;
				}
				if (this.rejectedApplicantsData[index]) {
					this.rejectedApplicantsData[index].is_read = true;
				}
			} else {
				if (this.recommendedApplicantsData[index]) {
					this.recommendedApplicantsData[index].is_read = true;
				}
			}
			if (response.status === 200 && response.Code === 200) {
				// console.log('mark as read success  jobID:' + this.vacancyID + ' recruitID:' + application.recruit_id);
			}
		});

	}

	// view profile video
	profileVideo(sectionData: any) {
		// console.log('profileVideo sectionData')
		// console.log(sectionData)
		let modal = this._modalService.open(ProfileVideoApplicantComponent, { backdrop: 'static', keyboard: false, windowClass: 'profile-video' });
		modal.componentInstance.profileVideoV2 = true;
		modal.componentInstance.sectionData = sectionData;
	}

	CandidateReScore(applicant: any, index: any, type: any) {
		let headertext: string = 'Recommend Applicant Rescore';
		let bodytext: string = 'Are you sure, you are about to take this action? Yes, will rescore this applicant and put it in New appicants list.';
		let status: boolean = false;
		if (type && type == 3) {
			headertext = 'Rejected Applicant Rescore';
			bodytext = 'Are you sure, you are about to take this action? Yes, will rescore this applicant and put them in recommended appicants list.';
			status = true;
		} else if (type && type == 1) {
			headertext = 'New Applicant Rescore';
			bodytext = 'Are you sure, you are about to take this action? Yes, will rescore this applicant and put them in recommended appicants list.';
			status = true;
		}

		let modal = this._modalService.open(ConfirmAlertComponent, { windowClass: 'hire-alert-popup', backdrop: 'static', keyboard: false });
		modal.componentInstance.header = headertext;
		modal.componentInstance.contentType = 'Text';
		modal.componentInstance.msg = bodytext;
		modal.result.then((closed) => {
			// On confirm CandidateReScore popup
			if (applicant.recruit_id) {
				// console.log('ReScore Candidate');
				this._jobBoardService.rescoreCandidate(this.vacancyID, applicant.recruit_id, status).subscribe((response: any) => {
					// console.log(response);
					if (response.Code === 200) {
						// show success message
						this._toastr.success('Candidate Rescored');
						applicant.recruit_score = response.Data;
						// Do we need to update data
						if (type == 3) {
							this.rejectedApplicantsData.splice(index, 1);
							if (applicant.recruit_score < 75) {
								this.allApplicantsData.push(applicant);
							} else {
								this.recommendedApplicantsData.push(applicant);
							}
						} else if (type == 1) {
							if (applicant.recruit_score < 75) {
								this.allApplicantsData.unshift(applicant);
							} else {
								this.allApplicantsData.splice(index, 1);
								this.recommendedApplicantsData.unshift(applicant);
							}
						} else {
							if (applicant.recruit_score < 75) {
								this.recommendedApplicantsData.splice(index, 1);
								this.allApplicantsData.unshift(applicant);
							} else {
								this.recommendedApplicantsData.unshift(applicant);
							}
						};
					} else {
						// show error message
						this._toastr.error('Something went wrong!');
					}
				});
			} else {
				// show error message
				this._toastr.error('Something went wrong!');
			}
		},
		(dismissed) => {

		});
	}

	resetScore(applicant: any, index: any, type?: any): void {
		let headertext = 'Reset Unnanu Score';
		let bodytext = 'Are you sure, you want to reset applicant score? Yes, will reset score for this applicant and put them in recommended or other appicants list.';
		let status = false;
		if (type && type == 1) {
			status = true;
		}

		let modal = this._modalService.open(ConfirmAlertComponent, { windowClass: 'hire-alert-popup', backdrop: 'static', keyboard: false });
		modal.componentInstance.header = headertext;
		modal.componentInstance.contentType = 'Text';
		modal.componentInstance.msg = bodytext;
		modal.result.then((closed) => {
			// On confirm reserScore popup
			if (applicant.recruit_id) {
				// console.log('Reset Candidate Score');
				this._jobBoardService.resetscoreCandidate(this.vacancyID, applicant.recruit_id, status).subscribe((response: any) => {
					// console.log(response);
					if (response.Code === 200) {
						// show success message
						this._toastr.success('Candidate Score is Reset');
						applicant.recruit_score = response.Data;
						// Update view data
						if (type == 1) {
							if (applicant.recruit_score < 75) {
								if (this.allApplicantsData.findIndex(data => data.recruit_id === applicant.recruit_id) === -1) {
									this.allApplicantsData.unshift(applicant);
								}
							} else {
								this.allApplicantsData.splice(index, 1);
								this.recommendedApplicantsData.unshift(applicant);
							}
						} else {
							if (applicant.recruit_score < 75) {
								this.recommendedApplicantsData.splice(index, 1);
								this.allApplicantsData.unshift(applicant);
							} else {
								if (this.recommendedApplicantsData.findIndex(data => data.recruit_id === applicant.recruit_id) === -1) {
									this.recommendedApplicantsData.unshift(applicant);
								}
							}
						}
					} else {
						// show error message
						this._toastr.error('Something went wrong!');
					}
				});
			} else {
				this._toastr.error('Something went wrong!');
			}
		},
		(dismissed) => {

		});
	}

	keywordScore(applicant: any): void {
		let modal = this._modalService.open(ShowKeywordsComponent, { windowClass: 'jobPositions showKeyword', backdrop: 'static', keyboard: false });
		modal.componentInstance.userTitle = `${applicant.recruit_first_name} ${applicant.recruit_last_name}'s Keyword Counts`;
		modal.componentInstance.vacancyID = this.vacancyID;
		modal.componentInstance.applicant = applicant;
	}

	rejectCandidate(applicant: any, index: any): void {
		let headertext = 'Reject Job Applicant'
		let bodytext = 'Are you sure, you want to reject this job applicant? Yes, will move this applicant to rejected candidate list.';

		let modal = this._modalService.open(ConfirmAlertComponent, { windowClass: 'hire-alert-popup', backdrop: 'static', keyboard: false });
		modal.componentInstance.header = headertext;
		modal.componentInstance.contentType = 'Text';
		modal.componentInstance.msg = bodytext;
		modal.result.then((closed) => {
			// On click rejectCandidate confirm
			if (applicant.recruit_id) {
				// console.log('Reject Candidate for this Job');
				this._jobBoardService.rejectCandidate(this.vacancyID, applicant.recruit_id).subscribe((response: any) => {
					// console.log(response);
					if (response.Code === 200) {
						this._toastr.success('Candidate is Rejected.');
						// Do we need to update data
						if (applicant.recruit_score < 75) {
							this.allApplicantsData.splice(index, 1);
						} else {
							this.recommendedApplicantsData.splice(index, 1);
						}
						this.rejectedApplicantsData.push(applicant);
				  	} else {
						// show error message
						this._toastr.error('Something went wrong!');
				  	}
				});
			}
		},
		(dismissed) => {

		});
	}

	GetApplicantNotes(applicant: any): void {
		let modal = this._modalService.open(ShowJobApplicantNotesComponent, { windowClass: 'jobPositions', backdrop: 'static', keyboard: false });
		modal.componentInstance.userTitle = `${this.vacancyID} - ${applicant.recruit_first_name} ${applicant.recruit_last_name}'s Notes`;
		modal.componentInstance.vacancyID = this.vacancyID;
		modal.componentInstance.applicant = applicant;
	}

	viewApplicantResponse(recruitId: any, applicantName: any, applicationDate: any): void {
		// console.log('viewApplicantResponse')
		// console.log(applicantName)
		// console.log(applicationDate)

		let modal = this._modalService.open(ShowApplicantAnswersComponent, { windowClass: 'application-answers', backdrop: 'static', keyboard: false });
		modal.componentInstance.jobdata = this.jobdata;
		modal.componentInstance.jobtitle = this.jobtitle;
		modal.componentInstance.vacancyID = this.vacancyID;
		modal.componentInstance.applicantName = applicantName;
		modal.componentInstance.applicationDate = applicationDate;
		modal.componentInstance.recruitId = recruitId;
	}

	closeThisDialog(): void {
		this._modal.dismiss();
	}

}
