import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class PaymentService {

	private serverUrl = environment.urls.paymentServerUrl;

	constructor(private _http: HttpClient) {

	}

	// create interview
    createSubscription(subscriptionData: any) {
		return this._http.post(`${this.serverUrl}subscription/hire/subscribe`, subscriptionData);
	}

	// reactivate subcription = 7/1/23/MB
	reactivateSubscription(id: any, subscriptionData: any) {
			return this._http.post(`${this.serverUrl}subscription/hire/reactivate/${id}`, subscriptionData);
		}

	// update subcription
	updateSubscription(subscriptionData: any) {
		return this._http.post(`${this.serverUrl}subscription/hire/changePlan`, subscriptionData);
	}

	// update card details
	updateCardDetails(tokenData: any) {
		return this._http.post(`${this.serverUrl}subscription/hire/card/update`, tokenData);
	}

}
