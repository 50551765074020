<div class="go-pro-pop-up-content">
	<form #checkoutForm="ngForm" novalidate>
		<div class="pop-up-left">
			<h2 class="go-pro-pop-title" *ngIf="selectedPlanName === 'pro' ">Unnanu Hire: Pro</h2>
			<h2 class="go-pro-pop-title" *ngIf="selectedPlanName === 'standard' ">Unnanu Hire: Standard</h2>
			<h2 class="go-pro-pop-title" *ngIf="selectedPlanName === 'job' ">Unnanu Hire: Jobboard</h2>
			<div class="card-details-wrapper">
				<div class="card-name-wrap">
					<div>
						<p>Name on card</p>
						<input class="form-control card-name" type="text" [(ngModel)]="cardName" name="name"
							required="">
					</div>
					<div class="card-thumbs">
						<div class="visa card"></div>
						<div class="master card"></div>
						<div class="discover card"></div>
						<div class="amex card"></div>
					</div>
				</div>
				<div class="card-details-wrap" ngxStripeCardGroup [elementsOptions]="elementsOptions">
					<div class="card-number">
							<p>Credit card number</p>
							<div class="form-control">
									<ngx-stripe-card-number [options]="cardOptions" (change)="onChange($event)"></ngx-stripe-card-number>
							</div>
					</div>
					<div class="card-expire">
							<p>Expires</p>
							<div class="form-control">
									<ngx-stripe-card-expiry [options]="cardOptions" (change)="onChange($event)"></ngx-stripe-card-expiry>
							</div>
					</div>
					<div class="card-cvv">
							<p>CVC</p>
							<div class="form-control">
									<ngx-stripe-card-cvc [options]="cardOptions" (change)="onChange($event)"></ngx-stripe-card-cvc>
							</div>
					</div>
				</div>
					<div class="card-address-wrap" style="padding-top: 50px;">
					<div class="card-address">
						<p>Address</p>
								<input type="text" class="form-control card-address" name="address"[(ngModel)]="address" required="">
				</div>
				<div class="card-postal">
						<p>Postal Code</p>
								<input type="text" class="form-control" name="postalCode" [(ngModel)]="postalCode" required="">
				</div>
			</div>
				<span class="error" style=" color: red;" [hidden]="!subsErrorFound">* {{subsErrorFoundMessage}} </span>
				<div class="form-check privacy-agree">
					<label class="form-check-label">
						<input class="form-check-input" type="checkbox" name="termsRequired" [(ngModel)]="termsRequired"
							required> By checking you agree to <a href="{{UNNANU_LANDING_PAGE}}/tos/"
							target="_blank">Unnanu Terms</a> &amp; <a href="{{UNNANU_LANDING_PAGE}}/privacy/"
							target="_blank">Privacy Policy</a>
					</label>
				</div>
			</div>
		</div>
		<div class="pop-up-right">
			<a (click)="selectedPlan='year'">
				<div *ngIf="selectedPlanName != 'job'" class="year-plan plan" [ngClass]="{'active' : selectedPlan=='year'}">
					<p class="plan-price">{{(selectedPlanAmount * 10) | currency}} /year</p>
					<p class="discount">Best value: Save ${{selectedPlanAmount * 2}}</p>
					<i class="verified-icon"></i>
				</div>
				<div *ngIf="selectedPlanName == 'job'" class="year-plan plan" [ngClass]="{'active' : selectedPlan=='year'}">
					<p class="plan-price">{{(selectedPlanAmount * 6) | currency}} /year</p>
					<p class="discount">Best value: Save ${{selectedPlanAmount * 6}}</p>
					<i class="verified-icon"></i>
				</div>
			</a>
			<a (click)="selectedPlan='month'">
				<div class="month-plan plan" [ngClass]="{'active' : selectedPlan=='month'}">
					<p class="plan-price">{{selectedPlanAmount | currency}} /month</p>
					<i class="verified-icon"></i>
				</div>
			</a>
			<p *ngIf="selectedPlanName != 'job'" class="description year-description" [ngClass]="{'active' : selectedPlan=='year'}">Your
				credit card will be charged {{(selectedPlanAmount * 10) | currency}}. This subscription will automatically
				renew at end of each term.</p>
		  <p *ngIf="selectedPlanName == 'job'" class="description year-description" [ngClass]="{'active' : selectedPlan=='year'}">Your
					credit card will be charged {{(selectedPlanAmount * 6) | currency}}. This subscription will automatically
					renew at end of each term.</p>
			<p class="description month-description" [ngClass]="{'active' : selectedPlan=='month'}">Your
				credit card will be charged {{selectedPlanAmount | currency}}. This subscription will automatically renew at end of
				each term.</p>
			<button class="btn btn-purchase-complete" [disabled]="!checkoutForm.valid || !cardValid || responseLoad"
				(click)="sendCardDetails()" [ngClass]="{'loading' : responseLoad}" type="submit">
				Purchase subscription
			</button>
			<button type="reset" class="btn btn-purchase-cancel" (click)="closeThisDialog()">Cancel</button>
		</div>
	</form>
</div>
