import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { StripeCardNumberComponent, StripeService } from 'ngx-stripe';
import { PaymentService } from '../../../core/services/payment.service';
import { UserService } from '../../../core/services/user.service';
import { environment } from 'src/environments/environment';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { Router } from '@angular/router';

@Component({
	selector: 'view-payment',
	templateUrl: './view-payment.component.html',
	styleUrls: ['./view-payment.component.scss']
})
export class ViewPaymentComponent implements OnInit {

	public UNNANU_LANDING_PAGE: string = environment.urls.UNNANU_LANDING_PAGE;

	@Input() selectedPlanAmount: any;
	@Input() selectedPlan: string = '';
	// assign package data
	@Input() selectedPlanName: string = '';

	@Input() type: string = 'CreateSubscription';

	public cardName: string = '';
	public cardValid: boolean = false;
	public termsRequired: boolean = false;

	public responseLoad: boolean = false;
	public subsErrorFound: boolean = false;
	public subsErrorFoundMessage: string = '';
	public showError: boolean = false;
	public errorStatus: string = '';

	public address: string = '';
  public postalCode: string = '';
  public activationPopup: boolean = false;

	public cardOptions: any = {
		showIcon: true,
		style: {
			base: {
				iconColor: '#666EE8',
				color: '#31325F',
				fontWeight: '300',
				fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
				fontSize: '18px',
				'::placeholder': {
					color: '#CFD7E0'
				}
			}
		}
	};

	public elementsOptions: StripeElementsOptions = {
		locale: 'en'
	};

	private _choosenPackage: string = '';

	@ViewChild(StripeCardNumberComponent)
	paymentElement!: StripeCardNumberComponent;

	constructor(private _modal: NgbActiveModal,
		private _router: Router,
		private _paymentService: PaymentService,
		private _userService: UserService,
		private _cookieService: CookieService,
		private _stripeService: StripeService) { }

	ngOnInit(): void {
	}

	// click send card details
	sendCardDetails(): void {
		// change button disabled and loading class active
		this.responseLoad = true;
		// hide error message from popup
		this.subsErrorFound = false;
		// calling stripe
		// console.log(this.selectedPlanName);

		// call stripe functions
		this._stripeService.createToken(this.paymentElement.element, { name: this.cardName,
			address_line1: this.address,
			address_zip: this.postalCode }).subscribe(this._stripeResponseHandler.bind(this));
			// console.log("this.selectedPlan: ", this.selectedPlan);
			// console.log("this.selectedPlanName: ", this.selectedPlanName);
		if (this.type === 'UpdateCardInfo') {
			if (this.selectedPlan === 'month' && this.selectedPlanName === 'ProV3') {
				this._choosenPackage = 'hire-pro-monthly-v3'
			} else if (this.selectedPlan === 'month' && this.selectedPlanName === 'StandardV3') {
				this._choosenPackage = 'hire-standard-month-v3'
			} else if (this.selectedPlan === 'month' && this.selectedPlanName === 'JobV3') {
				this._choosenPackage = 'hire-job-month-v3'
			} else if (this.selectedPlan === 'year' && this.selectedPlanName === 'JobV3') {
				this._choosenPackage = 'hire-job-yearly-v3'
			} else if (this.selectedPlan === 'year' && this.selectedPlanName === 'ProV3') {
				this._choosenPackage = 'hire-pro-yearly-v3'
			} else {
				this._choosenPackage = 'hire-standard-yearly-v3'
			}
		} else {
			if (this.selectedPlan === 'month' && this.selectedPlanName === 'pro') {
				this._choosenPackage = 'hire-pro-monthly-v3';
			} else if (this.selectedPlan === 'month' && this.selectedPlanName === 'standard') {
				this._choosenPackage = 'hire-standard-month-v3';
			} else if (this.selectedPlan === 'month' && this.selectedPlanName === 'job') {
				this._choosenPackage = 'hire-job-month-v3'
			} else if (this.selectedPlan === 'year' && this.selectedPlanName === 'job') {
				this._choosenPackage = 'hire-job-yearly-v3'
			} else if (this.selectedPlan === 'year' && this.selectedPlanName === 'pro') {
				this._choosenPackage = 'hire-pro-yearly-v3';
			} else {
				this._choosenPackage = 'hire-standard-yearly-v3';
			}
		}
	}

	// stripeResponseHandler function for card validation
	private _stripeResponseHandler(response: any): void {
		// console.log(response)
		// If card validation fail
		if (response.error) {
			// change button disabled and loading class active
			this.responseLoad = false;
			this.showError = true;
			if (response.error.message === 'Missing required param: card[exp_month].' || response.error.message === 'Missing required param: card[exp_year].') {

			} else {
				this.errorStatus = response.error.message;
			}
			// view error message in popup
			this.subsErrorFound = true
			this.subsErrorFoundMessage = response.error.message;
			// console.log(response.error.message)
		} else {
			// when card validation success
			this.showError = false;
			this.errorStatus = ''

			this.subsErrorFound = false;

			// get stripe token
			// console.log(response.token.id);

			// check subscription data are available or not
			if (this.selectedPlan) {

				if (this.type === 'CreateSubscription') {
					// cerate object
					let tempSubscribe: any = {};
					tempSubscribe.card_token = response.token.id;
					tempSubscribe.selected_plan = this._choosenPackage;

					// call cerate subscription
					this._paymentService.createSubscription(tempSubscribe).subscribe((response: any) => {
						// change button disabled and loading class active
						this.responseLoad = false;
						if (response.Code === 200) {
							// console.log(response);
							// console.log(this._choosenPackage);
							// change stage cookie
							// this._userService.setCookie('stage_Hire', 2)
							this.activationPopup = true;
							// this.closeThisDialog();
							this._userService.logout().subscribe((response) => {});
							// redirect user
							//this._userService.redirectUser(this._cookieService.get('stage_Hire'))
						} else {
							// console.log('Error with subscription');
							// console.log(response);
						}
					});
				}

				if (this.type === 'UpdateSubscription') {
					// cerate object
					let tempSubscribe: any = {};
					tempSubscribe.card_token = response.token.id;
					tempSubscribe.new_plan = this._choosenPackage;

					// call cerate subscription
					this._paymentService.updateSubscription(tempSubscribe).subscribe((response: any) => {
						// change button disabled and loading class active
						this.responseLoad = false;
						if (response.Code === 200) {
							// console.log(response);
							// console.log(this._choosenPackage);
							// change stage cookie
							if (this._cookieService.get('account_type') === '4') {
								this._cookieService.delete('stage_Hire');
								this._cookieService.delete('account_type');
								this._userService.setCookie('stage_Hire', '3');
								this._userService.setCookie('account_type', '1');
							}
							this.closeThisDialog();
							this._router.navigate(['/settings/payment']).then(() => {
								window.location.reload();
							});
						} else {
							// console.log('Error with subscription');
							// console.log(response);
						}
					});
				}

				if (this.type === 'ReactivateSubscription') {
					// cerate object
					let tempSubscribe: any = {};
					tempSubscribe.card_token = response.token.id;
					tempSubscribe.new_plan = this._choosenPackage;
					// call cerate subscription
					this._paymentService.reactivateSubscription(this._cookieService.get('user_id_Hire'), tempSubscribe).subscribe((response: any) => {
						// change button disabled and loading class active
						this.responseLoad = false;
						if (response.Code === 200) {
				// get token and stage from response
				      let token = response.Data.Token;
				      let stage = response.Data.Stage;
		          let user_id = response.Data.User_id;
				      let expireDate = response.Data.ExpiryOn;
				      let accountType = response.Data.AccountType;
							// change stage cookie
							this._cookieService.delete('stage_Hire');
							this._cookieService.delete('account_type');
							this._userService.setCookie('token_Hire', token, expireDate);
							this._userService.setCookie('stage_Hire', stage, expireDate);
							this._userService.setCookie('user_id_Hire', user_id, expireDate);
							this._userService.setCookie('account_type', accountType, expireDate);
							this.closeThisDialog();
							this._router.navigate(['/company']).then(() => {
								window.location.reload();
							});
						} else {
							// console.log('Error with subscription');
							// console.log(response);
						}
					});
				}

				if (this.type === 'UpdateCardInfo') {
					// cerate object
					let tempSubscribe: any = {};
					tempSubscribe.new_card_token = response.token.id;
					// call cerate subscription
					this._paymentService.updateCardDetails(tempSubscribe).subscribe((response: any) => {
						// change button disabled and loading class active
						this.responseLoad = false;
						if (response.Code === 200) {
							// console.log(response);
							// console.log(this._choosenPackage);
							this.closeThisDialog();
							window.location.reload();
						} else {
							// console.log('Error with subscription');
							// console.log(response);
						}
					});
				}

			}
		}
	}

	onChange(event: any): void {
		this.cardValid = event.error ? false : true;
	}

	closeThisDialog(): void {
		this.showError = false;
		this.errorStatus = '';
		this.subsErrorFound = false;
		this.subsErrorFoundMessage = '';
		this._modal.dismiss();
	}

}
