import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanLoadAuthGuard } from './core/guard/auth.guard';

export const routes: Routes = [
	{ path: '', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
	{ path: 'signup', loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule) },
	{ path: 'signIn', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
	{ path: 'resetPassword', loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
	{ path: 'changePassword', loadChildren: () => import('./change-password/change-password.module').then(m => m.ChangePasswordModule) },
	{ path: 'subscription', loadChildren: () => import('./subscription/subscription.module').then(m => m.SubscriptionModule), canLoad:[CanLoadAuthGuard] },
	{ path: 'company', loadChildren: () => import('./company/company.module').then(m => m.CompanyModule), canLoad:[CanLoadAuthGuard] },
	{ path: 'verifyBusiness', loadChildren: () => import('./verifyBusiness/verifyBusiness.module').then(m => m.VerifyBusinessModule), canLoad:[CanLoadAuthGuard] },
	{ path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashbaordModule), canLoad:[CanLoadAuthGuard] },
	{ path: 'recruit', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule), canLoad:[CanLoadAuthGuard] },
	{ path: 'findTalent', loadChildren: () => import('./findTalent/findTalent.module').then(m => m.FindTalentModule), canLoad:[CanLoadAuthGuard] },
	{ path: 'messages', loadChildren: () => import('./messages/messages.module').then(m => m.MessagesModule), canLoad:[CanLoadAuthGuard] },
	{ path: 'interviews', loadChildren: () => import('./interviews/interviews.module').then(m => m.InterviewsModule), canLoad:[CanLoadAuthGuard] },
	{ path: 'jobboard', loadChildren: () => import('./jobboard/jobboard.module').then(m => m.JobboardModule), canLoad:[CanLoadAuthGuard] },
	{ path: 'onboard', loadChildren: () => import('./onboard/onboard.module').then(m => m.OnboardModule), canLoad:[CanLoadAuthGuard] },
	{ path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule), canLoad:[CanLoadAuthGuard] },
	{ path: 'joinTeam', loadChildren: () => import('./join-team/join-team.module').then(m => m.JoinTeamModule)},
	{ path: 'activate', loadChildren: () => import('./activate/activate.module').then(m => m.ActivateModule)}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
