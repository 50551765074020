import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { JobBoardService } from '../../../core/services/jobBoard.service';
import { OfferService } from '../../../core/services/offer.service';
import { ConfirmAlertComponent } from '../confirm-alert/confirm-alert.component';
import { FileSizeExceedAlertComponent } from '../file-size-exceed-alert/file-size-exceed-alert.component';
import { UtilityService } from '../../../core/services/utility.service';

@Component({
	selector: 'create-talent-invite',
	templateUrl: './create-talent-invite.component.html',
	styleUrls: ['./create-talent-invite.component.scss']
})
export class CreateTalentInviteComponent implements OnInit {

	@Input() vacancyid: any;
	@Input() job_title: string = '';
	@Input() context: string = 'HEADER';

	// view status
	public viewStatus: number = 0;
	public Header_title: string = 'Create Unnanu Account for a Candidate';
	public templateData: any = {
		attachments: []
	};
	public talentData: any = {};
	public attData: any = {};
	public sendbutton: boolean = false;
	public uploadingAttachment: boolean = false;
	public url: string = '';
	public filename: string = '';

	constructor(public utilityService: UtilityService,
		private _element: ElementRef,
		private _modalService: NgbModal,
		private _modal: NgbActiveModal,
		private _offerService: OfferService,
		private _jobBoardService: JobBoardService,
		private _toastr: ToastrService) { }

	ngOnInit(): void {
		if (this.context === 'JOBBOARD') {
			this.Header_title = `Create candidate to apply for a ${this.job_title} position`;
		}
	}

	// remove uploaded attachment
	removeUploadedAttachment(): void {
		this.templateData.attachments?.splice(0, 1);
		this.sendbutton = true;
	}

	onFileChange(event: any): void {
		if (event.target.files.length && event.target.files[0].size <= 10485760 &&
			(event.target.files[0].type === 'application/pdf' ||
			event.target.files[0].type === 'application/msword' ||
			event.target.files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
			this.uploadAttachment(event.target.files[0]);
		} else {
			let modal = this._modalService.open(FileSizeExceedAlertComponent, { windowClass: 'alertNew', backdrop: 'static', keyboard: false });
			modal.componentInstance.errorStatus = 'offers';
			modal.result.then((closed) => {

			},
			(dismissed) => {
				this._element.nativeElement.querySelector('input[type="file"]').value = null;
			});
		}
	}

	// upload attachment
	uploadAttachment(filedata: any): void {
		//console.log(filedata);
		// show preloader
		this.uploadingAttachment = true;
		this._offerService.uploadTalentResume(filedata).subscribe((response: any) => {
		//	console.log(response);
			this.uploadingAttachment = false;
			this.sendbutton = false;
			if (response.Code === 200) {
				this.templateData.attachments.unshift(response.Data);
				this.url = response.Data.attachment_url;
				this.filename = response.Data.file_name;
				this.attData = response.Data;
			} else {
				// show error message
				this._toastr.error(`Resume upload failed... try again`);
			}
			this._element.nativeElement.querySelector('input[type="file"]').value = null;
		});
	}

	private _sendFinalTalentInvite(talentData: any): void {
		talentData.attachment_url = this.attData.attachment_url;
		talentData.filename = this.attData.file_name;
		talentData.attachment_type = this.attData.attachment_type;

		if (this.context === 'HEADER') {
			this._jobBoardService.sendTalentCreateInvite2(talentData).subscribe((response: any) => {
				if (response.Code === 200) {
					this._toastr.success(`Account created or updated and invited to apply`);
					this._modal.close(1);
				} else {
					this._toastr.error(`Failed to send please try again.`);
				}
			});
		}

		if (this.context === 'JOBBOARD') {
			this._jobBoardService.sendTalentCreateInvite(this.vacancyid, talentData).subscribe((response: any) => {
				if (response.Code === 200) {
					this._toastr.success(`Account created or updated and invited to apply`);
					this._modal.close(1);
				} else {
					this._toastr.error(`Failed to send please try again.`);
				}
			});
		}
	}

	sendTalentEmailInvite(talentData: any): void {
		this.sendbutton = true;
		//check email exists
		this._jobBoardService.checkEmailExists(talentData.talentEmail).subscribe((response: any) => {
			let data: any = {
				talentEmail: talentData.talentEmail,
				talentName: talentData.talentFirstName + ' ' + talentData.talentLastName,
				talentPhone: talentData.talentPhone
			};
			//console.log(response);
			if (response.Code === 200) {
				let modal = this._modalService.open(ConfirmAlertComponent, { windowClass: 'hire-alert-popup', backdrop: 'static', keyboard: false });
				modal.componentInstance.header = 'Candidate Email Exists';
				modal.componentInstance.msg = 'Do you want continue communicate with the candidate? Yes, will send email to candidate to update current Unnanu account. or Try reenter correct email address by clicking No.';
				modal.result.then((closed) => {
					this._sendFinalTalentInvite(data);
				},
				(dismissed) => {
					this.sendbutton = false;
				});
			} else {
			  this._sendFinalTalentInvite(data);
			}
		});
	}

	closeThisDialog(): void {
		this._modal.dismiss();
	}
}
