import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { Md5 } from 'ts-md5';
import { SocketIOService } from './socket-io.service';
import { routes } from '../../app-routing.module';
import { CalendarService } from './calendar.service';

@Injectable()
export class UserService {
	private serverUrl = environment.urls.serverUrl;
	private serverUrlV2 = environment.urls.serverUrlV2;
	private messageUrl = environment.urls.messageUrl;
	private hireothUrl = environment.urls.hireothUrl;

	constructor(private _http: HttpClient,
		private _router: Router,
		private _cookieService: CookieService,
		private _socketIOService: SocketIOService,
		private _calendarService: CalendarService) {

	}

	login(user: any) {

		let data: any = {
			Email: user.Email,
			Password: new Md5().appendStr(user.Password).end(),
		}

		return this._http.post(`${this.serverUrl}user/signin`, data);
	}

	forgotPassword(user: any) {
		return this._http.post(`${this.serverUrl}user/forgetpassword/${user.RestEmail}/`, {});
	}

	forgotPasswordApiToken(urlToken: any) {
		return this._http.post(`${this.serverUrl}user/forgetpassword/validate/${urlToken}`, {});
	}

	resetPassword(user: any) {

		let data: any = {
			New_password: new Md5().appendStr(user.New_password).end(),
            token_Hire: user.Token
		};

		return this._http.post(`${this.serverUrl}profile/resetPassword`, data);
	}

	// freeSignup(userId: any) {
	// 	return this._http.post(`${this.serverUrl}user/free/signup/${userId}`, {});
	// }

	resumeDatabaseRequest(userId: any) {
		return this._http.post(`${this.serverUrl}user/rdbrequest/${userId}`, {});
	}

	uploadW9Document(file: any) {

		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'undefined');

		let fd = new FormData();
		fd.append('UploadedFile', file);
		fd.append('FileType', 'PDF');

		return this._http.post(`${this.serverUrl}/wnine/validate`, fd, { headers: headers });
	}

	addMembers(members: any) {
		return this._http.post(`${this.serverUrl}account/add/members`, members);
	}

	createCompany(companyData: any) {
		return this._http.post(`${this.serverUrl}user/c2c/signup`, companyData);
	}

	getDashboardData() {
		return this._http.get(`${this.serverUrl}profile`);
	}

	getInterviewData() {
		return this._http.get(`${this.messageUrl}interviewsV12/hire/get/count`);
	}

	getOnboardData() {
		return this._http.get(`${this.messageUrl}offerv12/hire/get/count`);
	}

	getJobboardOverviewData(page: any, keyword: any) {
		return this._http.get(`${this.serverUrl}jobboard/overview?keyword=${keyword}&page=${page}`);
	}

	getHeaderData() {
		return this._http.get(`${this.serverUrl}profile/get/headerData`);
	}

	getRecentSearches() {
		return this._http.get(`${this.serverUrl}profile/recentSearches`);
	}

	getSavedSearches() {
		return this._http.get(`${this.serverUrl}profile/savedSearches`);
	}

	joinTeam(team: any) {

		let data: any = {
			Account_type_id: team.Account_type_id,
			First_name: team.First_name,
			Last_name: team.Last_name,
			Password: new Md5().appendStr(team.Password).end(),
			Referral_token: team.Referral_token,
		}

		return this._http.post(`${this.serverUrl}user/signup/member`, data);
	}

	joinTeamTokenCheck(token: any) {
		return this._http.get(`${this.serverUrl}account/getCompany/${token}`);
	}

	activateFreeUser(token: any) {
		return this._http.post(`${this.serverUrl}user/free/signup/${token}`, token);
	}

	// getListsData(emptyLists: boolean) {

	// 	let emptyLists_extension: string = '';

	// 	if (emptyLists) {
	// 		emptyLists_extension = '?show_empty_list=true';
	// 	}

	// 	return this._http.get(`${this.serverUrl}profile/getLists${emptyLists_extension}`);
	// }

	getListsData(emptyLists: boolean, keyword: string | null, page: any) {
		let emptyLists_extension: string = '';
			emptyLists_extension = '?show_empty_list=' + emptyLists;
			emptyLists_extension += '&searchKey=' + keyword;
			emptyLists_extension += '&page=' + page;

		return this._http.get(`${this.serverUrl}profile/getLists${emptyLists_extension}`);
	}
	
	getCompaniesData(emptyLists: boolean, keyword: string | null, page: any) {
		let emptyLists_extension: string = '';
			emptyLists_extension = '?show_empty_list=' + emptyLists;
			emptyLists_extension += '&searchKey=' + keyword;
			emptyLists_extension += '&page=' + page;

		return this._http.get(`${this.serverUrl}profile/getCompanies${emptyLists_extension}`);
	}

	getSelectedListsData(id: any) {
		return this._http.get(`${this.serverUrl}profile/getListsRecruit/${id}`);
	}

	createList(list: any) {
		return this._http.post(`${this.serverUrl}profile/createList`, list);
	}

	saveSearch(listData: any) {
		return this._http.post(`${this.serverUrl}profile/saveSearch`, listData);
	}

	searchResultsV2(searchObjectOnload: any, keyword: any, place: any, lastResultIndex: any, scrollTimes: any) {
		let location = place === 'undefined' ? 'none' : place;

		let headers = new HttpHeaders();
		headers = headers.append('Location', location);

		return this._http.post(`${this.serverUrlV2}search?searchKey=${keyword}&from=${scrollTimes * 10}`, searchObjectOnload, { headers: headers });
	}

	searchFiltersV3(keyword: any, place: any, searchObjectOnload: any) {
		let location = place === 'undefined' ? 'none' : place;

		let headers = new HttpHeaders();
		headers = headers.append('Location', location);

		return this._http.post(`${this.serverUrlV2}search/filters?searchKey=${keyword}`, searchObjectOnload, { headers: headers });
	}

	updateList(updatedList: any) {
		return this._http.post(`${this.serverUrl}profile/editList`, updatedList);
	}

	deleteList(listData: any) {
		return this._http.post(`${this.serverUrl}profile/removeList`, listData);
	}

	getProfileData(profileID: any) {
		return this._http.get(`${this.serverUrl}profile/recruit/get/${profileID}`);
	}

	getAllJobPositionsData(profileID: any) {
		return this._http.get(`${this.serverUrl}jobboard/vacancy/getAppliedVacancies/${profileID}`);
	}

	getInviteMemberCount() {
		return this._http.get(`${this.serverUrl}account/getPendingMember/count`);
	}

	createEmailInvite(inviteData: any) {
		return this._http.post(`${this.hireothUrl}jobboard/vacancy/einvitesignup/`, inviteData);
	}

	getRecruitNotes(recruitID: any) {
		return this._http.get(`${this.hireothUrl}jobboard/vacancy/getRecruitNotes/${recruitID}`);
	}

	saveRecruitNotes(recruitID: any, rNotes: any, rPrivate: any,) {

		let data: any = {
			recruit_id: recruitID,
			rnotes: rNotes,
			rprivate: rPrivate
		};

		return this._http.post(`${this.hireothUrl}jobboard/vacancy/rnotes/${recruitID}`, data);
	}

	getEmailList() {
		return this._http.get(`${this.hireothUrl}account/get/emailList`);
	}

	saveDistEmails(sortDistEmails: any, isReferral: any, isOther: any) {
		let data: any = {
			sortDistEmails: sortDistEmails,
			isReferral: isReferral,
			isOther: isOther
		};
		return this._http.post(`${this.hireothUrl}account/post/emailList`, data);
	}

	getOptoutEmailList() {
		return this._http.get(`${this.hireothUrl}account/get/optoutemails`);
	}

	saveOptoutEmails(sortDistEmails: any) {
		let data: any = {
			odistemails: sortDistEmails,
		};
		return this._http.post(`${this.hireothUrl}account/post/optoutemails`, data);
	}

	getJobEmailList() {
		return this._http.get(`${this.hireothUrl}account/get/jobemailList`);
	}

	getCompanyInfo() {
		return this._http.get(`${this.hireothUrl}account/get/companyInfo`);
	}

	postCompanyInfo(data: any) {
		return this._http.post(`${this.hireothUrl}account/post/companyInfo`, data);
	}

	saveJobDistEmails(id: any, sortDistEmails: any, jobTitle: any, active: any) {
		let data: any = {
			id: id,
			jobTitle: jobTitle,
			sortDistEmails: sortDistEmails,
			active: active
		};
		return this._http.post(`${this.hireothUrl}account/post/updatjobemailList`, data);
	}

	saveCompanyJobsUrl(cjobsurl: any, cjobsdist: any) {

		let data: any = {
			cjobsurl: cjobsurl,
			cjobsdist: cjobsdist
		};

		return this._http.post(`${this.hireothUrl}account/post/companyJUrl`, data);
	}

	sendOnDemandEmails(odistemails: any) {

		let data: any = {
			odistemails: odistemails
		};

		return this._http.post(`${this.hireothUrl}account/post/onDistEmails`, data);
	}

	getTalentList(page: number = 0) {
		return this._http.get(`${this.hireothUrl}account/get/talentList?page=${page}`);
	}

	getarchTalentList(page: number = 0) {
		return this._http.get(`${this.hireothUrl}account/get/archtalentList?page=${page}`);
	}

	getTalentSettings() {
		return this._http.get(`${this.hireothUrl}account/get/talent/CheckPref`);
	}

	getTalentById(id: any) {
		return this._http.get(`${this.hireothUrl}account/get/talentId/${id}`);
	}

	updateTalentDistribute(id: any, distribute: number) {
		return this._http.get(`${this.hireothUrl}account/dist/${id}/${distribute}`);
	}

	checkEmail(email: any) {
		return this._http.get(`${this.hireothUrl}account/talent/CheckEmail?doc=${email.toString()}`);
	}

	uploadResume(files: any) {

		let fd = new FormData();

		Object.values(files).forEach((val: any, key: any) => {
			fd.append('file' + key, val);
		});

		return this._http.post(`${this.hireothUrl}account/resume/upload`, fd);
	}

	saveTalent(data: any) {
		return this._http.post(`${this.hireothUrl}account/addorupdate/talentId`, data);
	}

	saveTalentSettings(data: any) {
		return this._http.post(`${this.hireothUrl}account/addorupdate/talentPref`, data);
	}

	searchSkills(query: any) {
		return this._http.get(`${this.hireothUrl}account/skills/${query.trim()}`).pipe(
			map((response: any) => {
				let skillObjs: any[] = [];
				response.Data.forEach((data: any) => {
					skillObjs.push(data.Skill);
				});
				return skillObjs;
			})
		);
	}

	getTalentEmailList() {
		return this._http.get(`${this.hireothUrl}account/get/talentemailList`);
	}

	saveTalentDistEmails(sortDistEmails: any, isReferral: any, isOther: any) {
		let data: any = {
			sortDistEmails: sortDistEmails,
			isReferral: isReferral,
			isOther: isOther
		};
		return this._http.post(`${this.hireothUrl}account/post/talentemailList`, data);
	}
	
	sendOnDemandTalentEmails(odistemails: any) {

		let data: any = {
			odistemails: odistemails
		};

		return this._http.post(`${this.hireothUrl}account/post/onTalentDistEmails`, data);
	}

	redirectUser(stage: any) {
		stage = Number(stage);
		if (this._cookieService.check('token_Hire')) {
			switch (stage) {
				case 0:
				// this.router.navigate(['/verifyBusiness']);
				// break;
				case 1:
					this._router.navigate(['/subscription']);
					break;
				case 2:
					this._router.navigate(['/company']);
					break;
				case 3:
					this._router.navigate(['/dashboard']);
					break;
				case 4:
					this._router.navigate(['/onboard']);
					break;
				default:
					this._router.navigate(['/']);
					break;
			}
		} else {
			this._router.navigate(['/']);
		}
	}



	logout() {
		return this._http.post(`${this.serverUrl}user/signout`, {}).pipe(map((result: any) => {
			this.clearUserData();
			return result;
		}));
	}

	clearUserData() {
		this._socketIOService.disconnect();
		this._cookieService.deleteAll();
		this._cookieService.deleteAll('/');
		this._router.resetConfig(routes);
		this._router.navigateByUrl('/');
	}

	setCookie(name: string, data: any, expireDate?: any, extraOptions?: any): void {
		let options: any = {
			sameSite: 'Lax',
			path: '/'
		};
		if (expireDate) {
			options.expires = new Date(expireDate);
		}
		if (extraOptions) {
			options = { ...options, extraOptions };
		}
		this._cookieService.set(name, data, options);
	}

	UpdateMsCalendarToken() {
		this._calendarService.getCalendarList().subscribe((response: any) => {
			let calendars = response.Data;
			for (let i = 0; i < calendars.length; i++) {
				if (calendars[i].calendar_type == 2) {
					this._calendarService.getMsAccessToken(calendars[i].token).subscribe((token_response: any) => {

						for (let j = 0; j < calendars[i].calendar_list.length; j++) {
							calendars[i].calendar_list[j].token = token_response.refresh_token;
						}
						this._calendarService.addCalendar(calendars[i].calendar_list).subscribe();
					});
					break;
				}
			}
		});
	}

}
