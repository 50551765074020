<div class="go-pro-pop-up-content">
	<form #checkoutForm="ngForm" novalidate>
		<div class="pop-up-left">
			<h2 class="go-pro-pop-title">Update Payment Information</h2>
			<p class="go-pro-pop-content">Your new payment method will be applied to next billing cycle. </p>
			<div class="card-details-wrapper">
				<div class="card-name-wrap">
					<div>
						<p>Name on card</p>
						<input class="form-control card-name" type="text" [(ngModel)]="cardName" name="name"
							required="">
					</div>
					<div class="card-thumbs">
						<div class="visa card"></div>
						<div class="master card"></div>
						<div class="discover card"></div>
						<div class="amex card"></div>
					</div>
				</div>
				<div class="card-details-wrap" ngxStripeCardGroup [elementsOptions]="elementsOptions">
					<div class="card-number">
						<p>Credit card number</p>
						<div class="form-control">
							<ngx-stripe-card-number [options]="cardOptions" (change)="onChange($event)">
							</ngx-stripe-card-number>
						</div>
					</div>
					<div class="card-expire">
						<p>Expires</p>
						<div class="form-control">
							<ngx-stripe-card-expiry [options]="cardOptions" (change)="onChange($event)">
							</ngx-stripe-card-expiry>
						</div>
					</div>
					<div class="card-cvv">
						<p>CVC</p>
						<div class="form-control">
							<ngx-stripe-card-cvc [options]="cardOptions" (change)="onChange($event)">
							</ngx-stripe-card-cvc>
						</div>
					</div>
				</div>
				<span class="error" style=" color: red;" [hidden]="!subsErrorFound">* {{subsErrorFoundMessage}} </span>
				<div class="form-check privacy-agree">
					<label class="form-check-label">
						<input class="form-check-input" type="checkbox" name="termsRequired" [(ngModel)]="termsRequired"
							required> By checking you agree to <a href="{{UNNANU_LANDING_PAGE}}/tos/"
							target="_blank">Unnanu Terms</a> &amp; <a href="{{UNNANU_LANDING_PAGE}}/privacy/"
							target="_blank">Privacy Policy</a>
					</label>
				</div>
			</div>
			<button class="btn btn-purchase-complete" [disabled]="!checkoutForm.valid || !cardValid || responseLoad"
				(click)="sendCardDetails()" [ngClass]="{'loading' : responseLoad}" type="submit">
				Update Information
			</button>
			<button type="reset" class="btn btn-purchase-cancel" (click)="closeThisDialog()">Cancel</button>
		</div>
	</form>
</div>
